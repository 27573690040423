




import {Component} from 'vue-property-decorator';
import TemplateComponent from "@/components/TemplateComponent.vue";
import FieldBase from "@/components/forms/FieldBase.vue";

@Component({
    components: {FieldBase, TemplateComponent}
})
export default class SimpleField extends FieldBase {
}
