import {ITrainingService} from "@/services/ITrainingService";
import {ITrainingListFilter} from "@/models/Training/ITrainingListFilter";
import Vue from "vue";
import {AxiosError} from "axios";
import {TrainingListVm} from "@/models/Training/TrainingListVm";
import TrainingUpdateVm from "@/models/Training/TrainingUpdateVm";
import TypeHelper from "@/constants/TypeHelper";
import FileResponse from "@/models/Common/FileResponse";
import {AxiosResponseToFileResponse} from "@/helpers/FileHelper";

export default class AxiosTrainingService implements ITrainingService {

  trainingList(filter: ITrainingListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<TrainingListVm> {
    return Vue.$axios.get<TrainingListVm>('trainings', {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      },
    })
      .then(res =>
        res.data
      ).catch((err: AxiosError) => {
        throw err.response?.data;
      })

  }

  update(training: TrainingUpdateVm): Promise<boolean> {
    const timeFrom = null === training.timeFrom || '' === training.timeFrom ? null : TypeHelper.convertTimeStringToInt(training.timeFrom);
    const timeTo = null === training.timeTo || '' === training.timeTo ? null : TypeHelper.convertTimeStringToInt(training.timeTo);

    return Vue.$axios.patch(`trainings/${training.id}`, {
      name: training.name,
      timeFrom: timeFrom,
      timeTo: timeTo,
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  delete(trainingId: number): Promise<boolean> {
    return Vue.$axios.delete(`trainings/${trainingId}`)
      .then(() => true)
      .catch(() => false);
  }

  downloadTrainingExcel(id: number): Promise<FileResponse> {
    return Vue.$axios.get(`trainings/${id}/excel`, {
      responseType: 'blob',
    })
      .then(res => AxiosResponseToFileResponse(res))
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

}
