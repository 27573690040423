import { render, staticRenderFns } from "./TemplateComponent.vue?vue&type=template&id=76c1c78a&scoped=true"
import script from "./TemplateComponent.vue?vue&type=script&lang=ts"
export * from "./TemplateComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c1c78a",
  null
  
)

export default component.exports