
















import {Component} from 'vue-property-decorator';
import TemplateComponent from "@/components/TemplateComponent.vue";
import FieldBase from "@/components/forms/FieldBase.vue";
import Icon from "@/components/helpers/Icon.vue";
import {Icons} from "@/constants/icons";

@Component({
  components: {Icon, FieldBase, TemplateComponent}
})
export default class PasswordField extends FieldBase {
  private showPassword = false;
  private icons = Icons;

  private toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
