




































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import TemplateComponent from "@/components/TemplateComponent.vue";
import Icon from "@/components/helpers/Icon.vue";

@Component({
  components: {Icon, TemplateComponent}
})
export default class FieldBase extends Vue {
  // eslint-disable-next-line
  @Prop() value: any;
  @Prop() label!: string | null;
  @Prop({type: Boolean, default: false}) horizontal!: boolean;
  @Prop({type: Boolean, default: false}) hasError!: boolean;
  @Prop({type: Boolean, default: false}) hasIconLeft!: boolean;
  @Prop({type: Boolean, default: false}) hasIconRight!: boolean;
  @Prop() error!: string | null;
  @Prop() iconLeft!: string | null;
  @Prop() iconRight!: string | null;
  @Prop() placeholder!: string | null;


  @Emit('iconClick')
  public iconClick(event: Event): Event {
    return event;
  }

}
