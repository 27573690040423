















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop({type: Boolean, default: false}) isLoading!: boolean;
}
