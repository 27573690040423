import Vue from 'vue';
import './plugins/fontawesome'
import './plugins/axios'
import './plugins/services'
import './plugins/vueCookies'
import App from './App.vue';
import router from './router';

import './scss/main.scss';
import store from './store'
import i18n from './i18n'

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
