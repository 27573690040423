export enum Icons {
  add = 'plus',
  edit = 'pen',
  save = 'floppy-disk',
  cancel = 'xmark',
  delete = 'trash',
  sortDesc = 'arrow-down',
  sortAsc = 'arrow-up',
  search = 'magnifying-glass',
  filter = 'filter',
  slash = 'slash',
  downloadTable = 'table',
  downloadPdf = 'file-pdf',

  bold = 'bold',
  italic = 'italic',
  underline = 'underline',


  showPassword = 'eye',
  hidePassword = 'eye-slash',
  password = 'key',

  user = 'user',
  vCard = 'address-card',
  address = 'house',
  birthday = 'cake-candles',
  email = 'envelope',
  emailAddress = 'at',
  phone = 'phone',
  licenseId = 'book',
  ahvNumber = 'id-card',

  text = 'pen-fancy',
  repeat = 'repeat',

  sport = 'person-running',
  training = 'stopwatch',
  belt = 'bezier-curve',
  invoices = 'file-invoice-dollar',
  membership = 'users',
  permission = 'user-shield',

  date = 'calendar-day',
  paymentState = 'hand-holding-dollar',
  bank = 'piggy-bank',
  generateInvoice = 'money-bill-wave',
  invoiceYear = 'circle',
  invoiceHalfYear = 'circle-half-stroke',
  invoiceMonth = 'calendar',
  sendEmail = 'envelope',
  sendReminderEmail = 'envelope',
  checkSendEmail = 'check',
  calendar = 'calendar-days',
  occupancy = 'calendar-day',
  back = 'angle-left',
  forward = 'angle-right',
}
