




















import {Component, Vue} from 'vue-property-decorator';
import Card from "@/components/card/Card.vue";
import SimpleField from "@/components/forms/SimpleField.vue";
import Icon from "@/components/helpers/Icon.vue";
import PasswordField from "@/components/forms/PasswordField.vue";
import Form from "@/components/forms/Form.vue";

@Component({
  components: {Form, PasswordField, Icon, SimpleField, Card}
})
export default class Login extends Vue {
  protected username = '';
  protected password = '';
  private error: string | null = null;

  protected login() {
    Vue.$authService.login(this.username, this.password)
      .then(res => {
         Vue.$authService.saveToken(res);
          this.$router.push({name: 'Users'});
        }
      ).catch(() => {
        this.error = this.$t('errors.loginFailed').toString();
      }
    )
  }
}
