











































import {Component, Vue} from 'vue-property-decorator';
import {SportListVm} from "@/models/Sport/SportListVm";
import Permissions from "@/constants/Permissions";

@Component
export default class Navigation extends Vue {

  protected showMobileMenu = false;

  protected toggleMobileNav() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  protected get isLoggedIn() {
    return true;
  }

  protected logout() {
    Vue.$authService.logout()
      .then(() => this.$router.push({name: 'Login'}));
  }

  protected sports: SportListVm | null = null;

  public get canFinance(): boolean {
    return this.$authService.can(Permissions.ViewFinance);
  }

  public get canEditSport(): boolean {
    return this.$authService.can(Permissions.EditSport);
  }

  public mounted() {
    Vue.$sportService.sportList(undefined, ['name'], [false], 0, 0)
      .then(res => this.sports = res);
  }
}
