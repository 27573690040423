









































import {Component, Vue} from 'vue-property-decorator';
import Navigation from "@/components/layout/Navigation.vue";
import TemplateComponent from "@/components/TemplateComponent.vue";

@Component({
  components: {TemplateComponent, Navigation}
})
export default class App extends Vue {
  public get onLoginPage(): boolean {
    return this.$route.name === 'Login';
  }
}
