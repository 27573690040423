export default class Permissions {
  public static readonly SuperAdmin: string = 'permission-super-admin';
  public static readonly Admin: string = 'permission-admin';
  public static readonly EditSport: string = 'permission-edit-sport';
  public static readonly CreateUser: string = 'permission-create-user';
  public static readonly EditUser: string = 'permission-edit-user';
  public static readonly EditGraduation: string = 'permission-edit-graduation';
  public static readonly EditMembership: string = 'permission-edit-membership';
  public static readonly ViewFinance: string = 'permission-view-finance';
  public static readonly CreateInvoice: string = 'permission-create-invoice';

  public static adminPermissions(): string[] {
    return [
      this.SuperAdmin,
      this.Admin,
    ];
  }

  public static sportPermissions(): string[] {
    return [
      this.EditSport,
    ];
  }

  public static userPermissions(): string[] {
    return [
      this.CreateUser,
      this.EditUser,
      this.EditGraduation,
      this.EditMembership,
    ];
  }

  public static invoicePermissions(): string[] {
    return [
      this.ViewFinance,
      this.CreateInvoice,
    ];
  }

  public static permissions(): string[] {
    return [
      this.SuperAdmin,
      this.Admin,
      this.EditSport,
      this.CreateUser,
      this.EditUser,
      this.EditGraduation,
      this.EditMembership,
      this.ViewFinance,
      this.CreateInvoice,
    ];
  }
}
