"use strict";

import Vue from 'vue';
import axios, {AxiosError} from "axios";
import {PluginObject} from "vue/types/umd";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


const config = {
  baseURL: process.env.VUE_APP_APIURL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (Vue.$authService.isAuthenticated() && !config.headers.Authorization) {
      config.headers['Authorization'] = `Bearer ${Vue.$authService.getToken().token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

let failedRequests = [] as { resolve: any; reject: any }[];
const processFailedRequests = (error: any, token: string | null = null) => {
  failedRequests.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedRequests = [];
};
let isRefreshing = false;
// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    const tokenExpired = 257 === error.response?.data.code;

    if (!tokenExpired
      || 401 !== error.response?.status
      || error.config?.url?.toLowerCase().includes('auth/refresh')
      || error.config?.url?.toLowerCase().includes('auth/logout')
    ) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedRequests.push({resolve, reject})
      }).then(token => {
        error.config.headers['Authorization'] = `Bearer ${token}`;
        return _axios(error.config);
      }).catch(err => {
        return Promise.reject(err);
      })
    }
    isRefreshing = true;
    return Vue.$authService.refresh()
      .then(res => {
        Vue.$authService.saveToken(res);
        const config = error.config;
        const token = Vue.$authService.getToken()
        if (token) {
          config.headers.Authorization = `Bearer ${token.token}`;
        }
        processFailedRequests(null, token.token);

        return new Promise((resolve, reject) => {
          _axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });
      })
      .catch((error) => {
        processFailedRequests(error, null);
        Promise.reject(error);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }
);


const Plugin = {
  install: function (vueInstance: typeof Vue, options?: any): void {
    vueInstance.axios = _axios;
    vueInstance.$axios = _axios;

    (window as any).axios = _axios;
    Object.defineProperties(vueInstance.prototype, {
      axios: {
        get() {
          return _axios;
        }
      },
      $axios: {
        get() {
          return _axios;
        }
      },
    });
  }
} as PluginObject<any>;


Vue.use(Plugin.install)
export default Plugin;
