import {ISportService} from "@/services/ISportService";
import Vue from 'vue';
import {BeltListVm} from "@/models/BeltListVm";
import {SportListFilter} from "@/models/Sport/SportListFilter";
import {SportListVm} from "@/models/Sport/SportListVm";
import {AxiosError} from "axios";
import {ITrainingListFilter} from "@/models/Training/ITrainingListFilter";
import {TrainingListVm} from "@/models/Training/TrainingListVm";
import TrainingCreate from "@/models/Training/TrainingCreate";
import TypeHelper from "@/constants/TypeHelper";
import SportDetailVm from "@/models/Sport/SportDetailVm";
import SportUpdateInvoiceDetailVm from "@/models/Sport/SportUpdateInvoiceDetailVm";
import SportUpdateQrInvoiceDetailVm from '@/models/Sport/SportUpdateQrInvoiceDetailVm';

export default class AxiosSportService implements ISportService {
  updateQrInvoiceDetail(sportId: number, updateModel: SportUpdateQrInvoiceDetailVm): Promise<boolean> {
    return Vue.$axios.put(`sports/${sportId}/qr-invoice-detail`, {
      ...updateModel
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }
  updateInvoiceDetail(sportId: number, updateModel: SportUpdateInvoiceDetailVm): Promise<boolean> {
    return Vue.$axios.put(`sports/${sportId}/invoice-detail`, {
      ...updateModel
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  beltList(sportId: number): Promise<BeltListVm> {
    return Vue.$axios.get<BeltListVm>(`sports/${sportId}/belts`)
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })
  }

  sport(sprotId: number): Promise<import("../models/Sport/SportDetailVm").default> {
    return Vue.$axios.get<SportDetailVm>(`sports/${sprotId}`)
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data
      });
  }

  sportList(filter: SportListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<SportListVm> {
    return Vue.$axios.get<SportListVm>('sports', {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      },
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })

  }

  trainingList(sportId: number, filter: ITrainingListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<TrainingListVm> {
    return Vue.$axios.get<TrainingListVm>(`sports/${sportId}/trainings`, {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      },
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })
  }

  createTraining(training: TrainingCreate): Promise<number> {
    return Vue.$axios.post<number>(`sports/${training.sportId}/trainings`, {
      name: training.name,
      timeFrom: TypeHelper.convertTimeStringToInt(training.timeFrom),
      timeTo: TypeHelper.convertTimeStringToInt(training.timeTo),
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })
  }

}
