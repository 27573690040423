import {AxiosResponse} from "axios";
import FileResponse from "@/models/Common/FileResponse";

export function AxiosResponseToFileResponse(res: AxiosResponse): FileResponse {
  const contentDisposition = res.headers['content-disposition'] as string;
  const filenameStart = 'filename="';
  const start = contentDisposition.indexOf(filenameStart) + filenameStart.length;
  const end = contentDisposition.indexOf('"', start + 1);
  let filename = contentDisposition.substring(start, end);
  if (filename.endsWith('_')) {
    filename = filename.substring(0, filename.length - 1);
  }

  return {blob: res.data, filename: filename};
}
