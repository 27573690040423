import {AuthServiceBase} from "@/services/AuthServiceBase";
import {IAuthService} from "@/services/IAuthService";
import {TokenResult} from "@/models/TokenResult";
import Vue from 'vue';
import {AxiosError} from "axios";
import UserSetPassword from "@/models/User/UserSetPassword";

export default class AxiosAuthService extends AuthServiceBase implements IAuthService {
  public assignPermission(userId: number, permission: string): Promise<null> {
    return Vue.$axios.post(`auth/${userId}/assign-permission`, {
      permission: permission,
    })
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public revokePermission(userId: number, permission: string): Promise<null> {
    return Vue.$axios.post(`auth/${userId}/revoke-permission`, {
      permission: permission,
    })
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  permissions(userId: number): Promise<string[]> {
    return Vue.$axios.get<{ entries: string[] }>(`auth/${userId}/permissions`)
      .then(res => res.data.entries)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      })
  }

  login(username: string, password: string): Promise<TokenResult> {
    return Vue.$axios.post<TokenResult>('auth/authenticate', {
      username: username,
      password: password,
    })
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  refresh(): Promise<TokenResult> {
    const token = this.getToken();
    return Vue.$axios.post('auth/refresh', {
      jwtToken: token.token,
      refreshToken: token.refreshToken,
    })
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  sendPasswordMail(userId: number): Promise<null> {
    return Vue.$axios.post(`auth/${userId}/send-password`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }


  setPassword(userId: number, setPassword: UserSetPassword): Promise<null> {
    return Vue.$axios.post(`auth/${userId}/set-password`, setPassword)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

}
