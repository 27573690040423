













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
    @Prop({type: Boolean, default: false}) private stack!: boolean;
    @Prop({type: Boolean, default: false}) private stackText!: boolean;
    @Prop() private textValue!: string;
    @Prop() private icon!: string | string[];
    @Prop({type: Boolean, default: false}) private isRight!: boolean;
    @Prop({type: Boolean, default: false}) private isLeft!: boolean;
    @Prop({type: Boolean, default: false}) private isSmall!: boolean;
    @Prop({type: Boolean, default: false}) private isLarge!: boolean;
}
