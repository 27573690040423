import Vue from 'vue';
import {PluginObject} from "vue/types/umd";
import AxiosUserService from "@/services/AxiosUserService";
import AxiosAuthService from "@/services/AxiosAuthService";
import AxiosSportService from "@/services/AxiosSportService";
import AxiosTrainingService from "@/services/AxiosTrainingService";
import AxiosInvoiceService from "@/services/AxiosInvoiceService";
import AxiosCalendarService from "@/services/AxiosCalendarService";

const authService = new AxiosAuthService();
const userService = new AxiosUserService();
const sportService = new AxiosSportService();
const trainingService = new AxiosTrainingService();
const invoiceService = new AxiosInvoiceService();
const calendarService = new AxiosCalendarService();


const Plugin = {
  install: function (vueInstance: typeof Vue): void {
    vueInstance.$authService = authService;
    vueInstance.$userService = userService;
    vueInstance.$sportService = sportService;
    vueInstance.$trainingService = trainingService;
    vueInstance.$invoiceService = invoiceService;
    vueInstance.$calendarService = calendarService;

    Object.defineProperties(vueInstance.prototype, {
      $userService: {
        get() {
          return userService;
        }
      },
      $authService: {
        get() {
          return authService;
        }
      },
      $sportService: {
        get() {
          return sportService;
        }
      },
      $trainingService: {
        get() {
          return trainingService;
        }
      },
      $invoiceService: {
        get() {
          return invoiceService;
        }
      },
      $calendarService: {
        get() {
          return calendarService;
        }
      },
    });
  }
} as PluginObject<any>;


Vue.use(Plugin.install)
export default Plugin;
