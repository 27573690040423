import {IAuthService} from "@/services/IAuthService";
import {TokenResult} from "@/models/TokenResult";
import Vue from 'vue';
import UserSetPassword from "@/models/User/UserSetPassword";

class TokenData {
  public exp: number | undefined;
  public roles: { [name: string]: string } | undefined;
  public permissions: { [name: string]: string } | undefined;
  public user: { id: number; name: string } | undefined;
  public iss: string | undefined;
  public aud: string | undefined;
  public iat: number | undefined;
  public nbf: number | undefined;
  public sub: string | undefined;
}

export abstract class AuthServiceBase implements IAuthService {
  protected readonly tokenCookieName = 'token'
  protected readonly refreshTokenCookieName = 'refreshToken'

  public isAuthenticated(): boolean {
    const token = Vue.$cookies.get(this.tokenCookieName);
    return null !== token;
  }

  public saveToken(tokenResult: TokenResult): void {
    Vue.$cookies.set(this.tokenCookieName, tokenResult.jwtToken);
    Vue.$cookies.set(this.refreshTokenCookieName, tokenResult.refreshToken);
  }


  public abstract login(username: string, password: string): Promise<TokenResult>;

  public abstract setPassword(userId: number, setPassword: UserSetPassword): Promise<null>;

  public abstract refresh(): Promise<TokenResult>;

  public abstract sendPasswordMail(userId: number): Promise<null>;

  public abstract permissions(userId: number): Promise<string[]>;
  public abstract assignPermission(userId: number, permission: string): Promise<null>;
  public abstract revokePermission(userId: number, permission: string): Promise<null>;

  public logout(): Promise<null> {
    Vue.$cookies.remove(this.tokenCookieName);
    Vue.$cookies.remove(this.refreshTokenCookieName);
    return Promise.resolve(null);
  }

  public getToken(): { token: string; refreshToken: string } {
    return {
      token: Vue.$cookies.get(this.tokenCookieName),
      refreshToken: Vue.$cookies.get(this.refreshTokenCookieName),
    };
  }

  public can(permission: string): boolean {
    const tokenData = this.tokenData();
    if (undefined === tokenData.permissions) {
      return false;
    }
    return Object.keys(tokenData.permissions).includes(permission);
  }

  public getUserId(): number | null {
    return this.tokenData().user?.id ?? null;
  }


  private tokenData(): TokenData {
    const token = this.getToken();
    return JSON.parse(atob(token.token.split('.')[1]));
  }
}


