




import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ComponentName extends Vue {
  @Prop() private msg!: string;

  public mounted() {
    // mounted
  }
}
