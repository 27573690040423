import {ICalendarService} from "@/services/ICalendarService";
import Vue from "vue";
import {AxiosError} from "axios";
import {OccupancyListFilter} from "@/models/Calendar/OccupancyListFilter";
import {OccupancyListVm} from "@/models/Calendar/OccupancyListVm";
import {CalendarEntry} from "@/models/Calendar/CalendarEntry";
import {CalendarFilter} from "@/models/Calendar/CalendarFilter";
import {OccupancyCreate} from "@/models/Calendar/OccupancyCreate";
import OccupancyUpdate from "@/models/Calendar/OccupancyUpdate";
import {OccupancyVm} from "@/models/Calendar/OccupancyVm";

export default class AxiosCalendarService implements ICalendarService {
  public calendar(filter: CalendarFilter | undefined): Promise<CalendarEntry[]> {
    return Vue.$axios.get<CalendarEntry[]>('calendars', {
      params: {
        ...filter,
      },
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })
  }

  occupancies(filter: OccupancyListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<OccupancyListVm> {
    return Vue.$axios.get<OccupancyListVm>('occupancies', {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      },
    })
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data;
      })
  }

  create(create: OccupancyCreate): Promise<number> {
    return Vue.$axios.post<number>('occupancies',
      create
    ).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }


  update(id: number, model: OccupancyUpdate): Promise<void> {
    return Vue.$axios.put<void>(`occupancies/${id}`,
      model
    ).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  addException(id: number, instant: string): Promise<void> {
    return Vue.$axios.post<void>(`occupancies/${id}/exceptions`,
      {
        instant: instant
      }
    ).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  deleteOccupancy(id: number): Promise<void> {
    return Vue.$axios.delete<void>(`occupancies/${id}`).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  detail(id: number): Promise<OccupancyVm> {
    return Vue.$axios.get<OccupancyVm>(`occupancies/${id}`)
      .then(res => res.data)
      .catch((err: AxiosError) => {
        throw err.response?.data
      });
  }

}
