import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "user" */ '../views/Users.vue'),
    meta: {
      needsAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/Users.vue'),
  },
  {
    path: '/users/:id',
    name: 'User',
    props: ({params}) => ({id: Number.parseInt(params.id) || null}),
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/UserDetail.vue'),
  },
  {
    path: '/sports/:id',
    name: 'Sport',
    props: ({params}) => ({id: Number.parseInt(params.id) || null}),
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import(/* webpackChunkName: "sport" */ '../views/SportDetail.vue'),
  },
  {
    path: '/invoices',
    name: 'Invoices',
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/Invoices.vue'),
  },
  {
    path: '/invoices/:id',
    name: 'Invoice',
    props: ({params}) => ({id: Number.parseInt(params.id) || null}),
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/InvoiceDetail.vue'),
  },

  {
    path: '/dojo-calendar',
    name: 'DojoCalendar',
    meta: {
      needsAuth: true,
      rolesNeeded: [],
    },
    component: () => import('../views/DojoCalendar.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (undefined !== to.meta && to.meta.needsAuth) {
    if (!Vue.$authService.isAuthenticated()) {
      next({name: 'Login'})
      return;
    }
  }
  next();
})

export default router;
