import {IUserService} from "@/services/IUserService";
import {UserListVm} from "@/models/User/UserListVm";
import {UserListFilter} from "@/models/User/UserListFilter";
import {UserDetailVm} from "@/models/User/UserDetailVm";
import {UserPromotionsVm} from "@/models/Promotion/UserPromotionsVm";
import {UserMembershipsVm} from "@/models/UserMembershipsVm";
import {UserCreate} from "@/models/User/UserCreate";
import {MembershipCreate} from "@/models/MembershipCreate";
import Vue from "vue";
import {AxiosError} from "axios";
import UserUpdateParentInfoVm from '@/models/User/UserUpdateParentInfoVm';
import UserUpdatePersonalInfoVm from "@/models/User/UserUpdatePersonalInfoVm";
import {MembershipUpdate} from "@/models/MembershipUpdate";
import {PromotionCreate} from "@/models/Promotion/PromotionCreate";
import {PromotionUpdate} from "@/models/Promotion/PromotionUpdate";
import FileResponse from "@/models/Common/FileResponse";
import {AxiosResponseToFileResponse} from "@/helpers/FileHelper";

export default class AxiosUserService implements IUserService {

  addMembership(membershipCreate: MembershipCreate): Promise<null> {
    return Vue.$axios.post<number>(`users/${membershipCreate.userId}/memberships`, membershipCreate)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  create(create: UserCreate): Promise<number> {
    return Vue.$axios.post<number>('users',
      create
    ).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  delete(userId: number): Promise<null> {
    return Vue.$axios.delete<null>(`users/${userId}`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      })
  }


  updateMembership(id: number, membershipUpdate: MembershipUpdate): Promise<boolean> {
    return Vue.$axios.put(`memberships/${id}`, membershipUpdate)
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  deleteMembership(id: number): Promise<boolean> {
    return Vue.$axios.delete(`memberships/${id}`)
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  memberships(userId: number): Promise<UserMembershipsVm> {
    return Vue.$axios.get<UserMembershipsVm>(`users/${userId}/memberships`)
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  promotions(userId: number): Promise<UserPromotionsVm> {
    return Vue.$axios.get<UserPromotionsVm>(`users/${userId}/promotions`)
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  updatePersonalInformation(user: UserUpdatePersonalInfoVm): Promise<boolean> {
    return Vue.$axios.put(`users/${user.id}/personal-information`, {
      ...user
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  updateParentInformation(user: UserUpdateParentInfoVm): Promise<boolean> {
    return Vue.$axios.put(`users/${user.id}/parent-information`, {
      ...user
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  userDetail(id: number): Promise<UserDetailVm> {
    return Vue.$axios.get<UserDetailVm>(`users/${id}`)
      .then(res => res.data);
  }

  userList(filter: UserListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<UserListVm> {

    return Vue.$axios.get<UserListVm>('users', {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      },
    })
      .then(res =>
        res.data
      )

  }

  updateFamilyMembers(userId: number, familyMemberIds: number[]): Promise<boolean> {
    return Vue.$axios.put(`/users/${userId}/family-members`, {
      familyMemberIds: familyMemberIds
    })
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  addPromotion(promotionCreate: PromotionCreate): Promise<null> {
    return Vue.$axios.post(`/users/${promotionCreate.userId}/promotions`, {
      beltId: promotionCreate.beltId,
      when: promotionCreate.when
    })
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  deletePromotion(id: number): Promise<boolean> {
    return Vue.$axios.delete(`promotions/${id}`)
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  updatePromotion(promotionId: number, promotionUpdate: PromotionUpdate): Promise<boolean> {
    return Vue.$axios.put(`promotions/${promotionId}`, promotionUpdate)
      .then(() => true)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  vCard(id: number, parents: boolean): Promise<FileResponse> {
    return Vue.$axios.get(`users/${id}/v-card?parents=${parents}`, {
      responseType: 'blob',
    })
      .then(res => AxiosResponseToFileResponse(res))
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

}
