













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Form extends Vue {
  @Prop() errorSummary!: string | null;
}
