import Vue from 'vue'
import VueI18n, {LocaleMessages} from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de-CH',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de-CH',
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    'de-CH': {
      short: {
        year: "numeric", month: "numeric", day: "numeric",
      },
      long: {
        year: "numeric", month: "short", day: "numeric"
      },
      shorter: {
        year: '2-digit', month: "short", day: "numeric"
      },
      shortest: {
        year: '2-digit', month: "numeric", day: "numeric"
      },
      month: {
        month: "long",
      },
      time: {
        hour: "2-digit", minute: "2-digit",
      },
      full: {
        year: "numeric", month: "short", day: "numeric",
        hour: "2-digit", minute: "2-digit",
      },
      weekday: {
        weekday: "long"
      },
      calendarEntry: {
        weekday: "short",
        day: "numeric",
        month: "numeric"
      }
    },
  },
  numberFormats: {
    'de-CH': {
      currency: {
        style: 'currency',
        currency: 'CHF',
      }
    }
  }
})
