import {IInvoiceService} from "@/services/IInvoiceService";
import InvoiceDetailVm from "@/models/Invoice/InvoiceDetailVm";
import InvoiceListFilter from "@/models/Invoice/InvoiceListFilter";
import InvoiceListVm from "@/models/Invoice/InvoiceListVm";
import Vue from 'vue';
import InvoiceGenerate from "@/models/Invoice/InvoiceGenerate";
import {AxiosError} from "axios";
import InvoiceUpdatePositions from '@/models/Invoice/InvoiceUpdatePositions';
import FileResponse from "@/models/Common/FileResponse";
import UserTimeInvoiceReportFilter from "@/models/Invoice/UserTimeInvoiceReportFilter";
import {AxiosResponseToFileResponse} from "@/helpers/FileHelper";

export default class AxiosInvoiceService implements IInvoiceService {
  updateInvoiceLines(id: number, updateModel: InvoiceUpdatePositions): Promise<null> {
    return Vue.$axios.put(`invoices/${id}/invoice-lines`, updateModel)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  payMonthly(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/pay-monthly`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  payHalfYear(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/pay-half-year`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  payFullYear(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/pay-full-year`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  send(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/send-email`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  sendReminder(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/send-reminder-email`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  markAsSent(id: number): Promise<null> {
    return Vue.$axios.post(`invoices/${id}/mark-as-sent`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public generateInvoices(invoiceGenerate: InvoiceGenerate): Promise<null> {
    return Vue.$axios.post(`invoices/generate`, invoiceGenerate).then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public invoiceDetail(id: number): Promise<InvoiceDetailVm> {
    return Vue.$axios.get<InvoiceDetailVm>(`invoices/${id}`)
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public invoiceList(filter: InvoiceListFilter | undefined, sortBy: string[] | undefined, sortDesc: boolean[] | undefined, page: number | undefined, perPage: number | undefined): Promise<InvoiceListVm> {
    return Vue.$axios.get<InvoiceListVm>(`invoices`, {
      params: {
        ...filter,
        'sortBy': sortBy,
        'sortDesc': sortDesc?.map(sd => sd ? 1 : 0),
        'page': page,
        'perPage': perPage
      }
    })
      .then(res => res.data)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  pdf(id: number): Promise<FileResponse> {
    return Vue.$axios.get(`invoices/${id}/pdf`, {
      responseType: 'blob',
    })
      .then(AxiosResponseToFileResponse)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  delete(id: number): Promise<null> {
    return Vue.$axios.delete(`invoices/${id}`)
      .then(() => null)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  supporterInvoice(id: number): Promise<FileResponse> {
    return Vue.$axios.get(`supporter-invoices/${id}/pdf`, {
      responseType: 'blob',
    })
      .then(AxiosResponseToFileResponse)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public userTimeInvoiceReport(filter: UserTimeInvoiceReportFilter): Promise<FileResponse> {
    return Vue.$axios.get(`invoices/user-time-invoice-report-excel`, {
      params: filter,
      responseType: 'blob',
    })
      .then(AxiosResponseToFileResponse)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

  public oneOffInvoice(sportId: number, title: string, text: string, amount: number): Promise<FileResponse> {
    return Vue.$axios.get(`one-off-invoices/${sportId}/pdf`, {
      params: {
        title: title,
        text: text,
        amount: amount,
      },
      responseType: 'blob',
    })
      .then(AxiosResponseToFileResponse)
      .catch((e: AxiosError) => {
        throw e.response?.data;
      });
  }

}
